<template>
  <div>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
    </b-input-group>

    <b-button class="mr-1" variant="primary" @click="list" :disabled="busy.list">
      검색
      <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
    </b-button>
    <b-button class="" variant="success" @click="showAddModal" :disabled="busy.addBrand">
      추가
      <b-spinner class="ml-1" small v-if="busy.addBrand"></b-spinner>
    </b-button>

    <hr/>

    <div class="clearfix mb-1">
      <div class="pull-right">
        <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="Brands"></xlsx>
      </div>
    </div>

    <c-table id="brandExcept" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 브랜드'" @get-more="list(true)">
    </c-table>

    <b-modal title="수집제외 브랜드 추가" size="xl" v-model="modal.add">
      <div class="label-sm">수집 제외할 브랜드명</div>
      <b-input v-model="form.add.brand_nm" placeholder="ex) SomeBrand"></b-input>
      <div class="label-sm">수집 제외 대상 Shop ID (비워둘 경우 전체 대상)</div>
      <shop-preset v-model="form.add.shop"></shop-preset>
      <div class="label-sm">비고</div>
      <b-form-textarea v-model="form.add.desc" placeholder="수집 제외하는 이유를 적어주세요"></b-form-textarea>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="addBrand()">
          추가
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal title="수집제외 브랜드 수정" size="xl" v-model="modal.update">
      <div class="label-sm">수집 제외할 브랜드명</div>
      <b-input v-model="item.brand_nm" placeholder="ex) SomeBrand" readonly></b-input>
      <div class="label-sm">수집 제외 대상 Shop ID (비워둘 경우 전체 대상)</div>
      <shop-preset ref="updateShopPreset" v-model="item.shop"></shop-preset>
      <div class="label-sm">비고</div>
      <b-form-textarea v-model="item.desc" placeholder="수집 제외하는 이유를 적어주세요"></b-form-textarea>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="updateBrand()">
          수정
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import cTable from '@/views/TableBase.vue'
import xlsx from '@/views/modules/Xlsx.vue'

export default {
  name: "BrandExcept",
  components: {cTable, xlsx},
  data() {
    return {
      search: '',
      shopMap: {},
      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'brand_nm', label: '수집 제외할 브랜드명', class: 'text-center'},
          {key: 'html1', label: '수집 제외 대상 Shop ID', class: 'text-center'},
          {key: 'desc', label: '비고', class: 'text-center'},
          {
            key: '_actions',
            label: '기능',
            class: 'text-center w-125px',
            buttons: [
              {label: '수정', variant: 'success', event: 'showUpdateModal'},
              {label: '삭제', variant: 'danger', event: 'deleteBrand'}
            ]
          },
        ]
      },
      defaultForm: {
        list: {
          search: ''
        },
        add: {
          brand_nm: '',
          shop_ids: '',
          desc: '',
        }
      },
      form: {
        list: {
          search: ''
        },
        add: {
          brand_nm: '',
          shop_ids: '',
          desc: '',
        }
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, addBrand: false, updateBrand: false, deleteBrand: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false, update: false},
      perPage: 20,

      xlsx: {
        selected: [],
        keys: ['brand_nm', 'shop_ids'],
        labels: ['브랜드명(영문)', '제외 대상 Shop ID'],
      }
    };
  },
  async created() {
    await this.$api.setMeta(this, 'shop');
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.form.list = this.$utils.clone(this.defaultForm.list);
    this.form.add = this.$utils.clone(this.defaultForm.add);
    this.list();
  },
  methods: {
    async list(more) {
      let j = await this.$api.postTable(this, '/meta/brand/except', {search: this.form.list.search}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      if (!e.shop_ids) {
        e.html1 = '전체 SHOP';
      } else if (e.shop_ids.length <= 5) {
        e.html1 = e.shop_ids.map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}. ${(this.shopMap[shop_id] || {}).boutique || ''}</a>`).join('');
      } else {
        e.html1 = e.shop_ids.slice(0, 15).map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}</a>`).join('');
        if (e.shop_ids.length > 15) {
          e.html1 += `<span class="badge badge-success mr-1">외 ${e.shop_ids.length - 15} 개 SHOP</span>`;
        }
      }
      return e;
    },
    btnAction (row, event) {
      if (event === 'showUpdateModal') {
        this.showUpdateModal(row);
      } else if (event === 'deleteBrand') {
        this.deleteBrand(row);
      }
    },
    showAddModal() {
      this.form.add = this.$utils.clone(this.defaultForm.add);
      this.form.add.shop = [];
      this.modal.add = true;
    },
    showUpdateModal(row) {
      this.item = this.$utils.clone(row.item);
      this.item.shop = [];
      if (this.item.shop_ids) {
        this.item.shop = this.item.shop_ids.map(e => this.shopMap[e]);
      }
      this.modal.update = true;
    },
    async addBrand() {
      this.modal.add = false;
      const form = this.form.add;
      const shop_ids = form.shop.map(e => e.shop_id);

      this.busy.addBrand = true;
      let j = await this.$api.postJson('/meta/brand/addExcept', {brand_nm: form.brand_nm, shop_ids, desc: form.desc});
      this.busy.addBrand = false;
      if (j) {
        this.list();
      }
    },
    async updateBrand() {
      this.modal.update = false;
      const shop_ids = this.item.shop.map(e => e.shop_id);

      this.busy.updateBrand = true;
      let j = await this.$api.postJson('/meta/brand/updateExcept', {brand_nm: this.item.brand_nm, shop_ids, desc: this.item.desc});
      this.busy.updateBrand = false;
      if (j) {
        this.list();
      }
    },
    async deleteBrand(row) {
      if (!confirm(`${row.item.brand_nm} 브랜드를 수집제외 브랜드에서 삭제하시겠습니까?`)) return;

      this.busy.deleteBrand = true;
      let j = await this.$api.postJson('/meta/brand/removeExcept', {brand_nm: row.item.brand_nm});
      this.busy.deleteBrand = false;
      if (j) {
        this.list();
      }
    },
    preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      if (!this.xlsx.selected.length) return alert('다운받을 브랜드를 선택해주세요');
      return true;
    },
  }
}
</script>
