<template>
  <div>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
    </b-input-group>
    <category-preset v-model="form.list.category"></category-preset>
    <b-button class="mt-1 mr-1" variant="primary" @click="list" :disabled="busy.list">
      검색
      <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
    </b-button>
<!--    <b-button class="" variant="success" @click="showModal" :disabled="busy.addCategory">
      추가
      <b-spinner class="ml-1" small v-if="busy.addCategory"></b-spinner>
    </b-button>-->

    <hr/>

    <div class="clearfix mb-1">
      <div class="pull-right">
        <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys"></xlsx>
      </div>
    </div>

    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.list.length + ' 개 카테고리'" @get-more="list(true)">
    </c-table>

    <b-modal title="카테고리 추가" size="lg" v-model="modal.add">
      <div class="label-sm">카테고리 코드</div>
      <b-input v-model="form.add.category" placeholder="카테고리 코드를 3자리 단위의 숫자로 입력해주세요(ex: 009002100)"></b-input>
      <div class="label-sm">카테고리명</div>
      <b-input v-model="form.add.category_nm" placeholder="카테고리명을 입력해주세요"></b-input>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="addCategory()">
          추가
        </b-button>
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import xlsx from '@/views/modules/Xlsx.vue'
import * as momentBiz from "moment-business-days";

export default {
  name: "CategoryList",
  components: {xlsx},
  data() {
    return {
      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'category', label: '카테고리 코드'},
          {key: 'full_nm', label: '카테고리 경로'},
          {key: 'category_nm', label: '카테고리명', class: 'text-center'},
          {key: 'level', label: '카테고리 차수', class: 'text-center'},
          {key: 'html1', label: '최종 카테고리 여부', class: 'text-center'},
          {key: 'keywords', label: '등록 키워드 수', class: 'text-center'},
        ]
      },
      form: {
        list: {
          search: '',
          category: [],
        },
        add: {
          category: '',
          category_nm: '',
        }
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, addCategory: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {add: false},
      perPage: 20,

      category: [],
      categoryMap: {},
      xlsx: {
        selected: [],
        keys: ['category', 'category_nm', 'full_nm', 'level', 'html1', 'keywords'],
        labels: ['카테고리 코드', '카테고리명', '카테고리 경로', '카테고리 차수', '최종 카테고리 여부', '등록 키워드 수'],
      }
    };
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    let meta = await this.$api.getMeta('category');

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    // 한번 더 루프를 돌아 full name 을 확보한다.
    this.category.forEach(e=>{
      e.full_nm = Array(e.category.length / 3).fill(0).map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
    });

    this.list();
  },
  methods: {
    async list(more) {
      const category = this.form.list.category.map(e => e.value);
      await this.$api.postTable(this, '/meta/category', {search: this.form.list.search, category}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.level = e.category.length / 3;
      e.html1 = e.final ? '최종' : '';
      e.full_nm = (this.categoryMap[e.category] || {}).full_nm;
    },
    showModal() {
      this.modal.add = true;
      this.form.add.category = '';
      this.form.add.category_nm = '';
    },
    async addCategory() {
      this.modal.add = false;
      this.busy.addCategory = true;
      let j = await this.$api.postJson('/meta/category/add', {...this.form.add});
      this.busy.addCategory = false;
      if (j) {
        this.list();
      }
    },
    preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      if (!this.xlsx.selected.length) return alert('다운받을 카테고리를 선택해주세요');
      return true;
    },
  }
}
</script>
