<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="카테고리 관리">
        <category-list></category-list>
      </b-tab>
      <b-tab title="카테고리 매핑">
        <category-mapping></category-mapping>
      </b-tab>
<!--      <b-tab title="수집제외 카테고리 관리">
        <category-except></category-except>
      </b-tab>-->
    </b-tabs>
  </div>
</template>

<script>
import categoryList from '@/views/meta/CategoryList.vue'
import categoryMapping from '@/views/meta/CategoryMapping.vue'
import categoryExcept from '@/views/meta/CategoryExcept.vue'

export default {
  name: "Category",
  title: '카테고리 관리',
  components: {categoryList, categoryMapping, categoryExcept},
  data() {
    return {
      tabIndex: 0,
    }
  }
}
</script>
